///////////////////////////////////////
// css theme variables / settings


//$font-family:       "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family: sans-serif;

//$code-font-family:  Menlo, Monaco, "Courier New", monospace;
$code-font-family: monospace;
//TODO: Rausfinden ob ich die auskommentierten Fonts einfach so verwenden darf.
//TODO: noch besser: Schöne, freie Fonts finden und rausfinden wie man die einbettet.


// masthead ist die Zeile ganz oben (Navigation)
$masthead-color:         #505050;
$masthead-small-color:   #C0C0C0;

$post-title-color:       #303030;
$post-date-color:        #9a9a9a;


$body-color:            #515151;
$body-background-color: #fff;

$link-color:            #268bd2;

$headings-color:        #313131;    // h1,h2,h3,h4,h5,h6

$strong-color:          #303030;    // strong

$pre-background-color:  #f9f9f9;    // pre

$blockquote-color:        #7a7a7a;  // blockquote
$blockquote-border-color: #e5e5e5;

$table-border-color:         #e5e5e5;
$table-odd-background-color: #f9f9f9;


