@import 'settings';

@import 'default_pygment';

html {
  font-family: $font-family;
  font-size:   1em;
  line-height: 1.5;
}

body {
  color:            $body-color;
  background-color: $body-background-color;
}

video {
   width: 100%;
   height: auto;
}

//////////////
// Links

a {
  color:           $link-color;
  text-decoration: none;

  strong {
    color: inherit;
  }
  
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}


////////////////////////////
// Headings

h1, h2, h3, h4, h5, h6 {
  margin-bottom:  .5rem;
  font-weight:    bold;
  line-height:    1.25;
  color:          $headings-color;
  text-rendering: optimizeLegibility;
}
h1 {
  font-size: 2rem;
}
h2 {
  margin-top: 1rem;
  font-size: 1.5rem;
}
h3 {
  margin-top: 1.5rem;
  font-size: 1.25rem;
}
h4, h5, h6 {
  margin-top: 1rem;
  font-size: 1rem;
}

/////////////
// Body text

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

strong {
  color: $strong-color;
}


/////////////
// Lists

ul, ol, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

dt {
  font-weight: bold;
}
dd {
  margin-bottom: .5rem;
}


//////////////////////
// Code

code,
pre {
  font-family: $code-font-family;
}
code {
  padding: .25em .5em;
  font-size: 85%;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: .8rem;
  line-height: 1.4;
  background-color: $pre-background-color;

  code {
    padding: 0;
    font-size: 100%;
    color: inherit;
    background-color: transparent;
  }
}  

/////////////////////
// Quotes

blockquote {
  padding: .5rem 1rem;
  margin: .8rem 0;
  color: $blockquote-color;
  border-left: .25rem solid $blockquote-border-color;

  p:last-child {
    margin-bottom: 0;
  }
}


/////////////////////////////
// Images:
img {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  margin-top: .5em;
  margin-bottom: .5em;
  //border-radius: 2em;
}


//////////////////////
// Tables

table {
  margin-bottom: 1rem;
  width: 100%;
  border: 1px solid $table-border-color;
  border-collapse: collapse;

  td,
  th {
   padding: .25rem .5rem;
   border: 1px solid $table-border-color;
  }

  tr:nth-child(odd) td,
  tr:nth-child(odd) th {
    background-color: $table-odd-background-color;
  }
}



////////////////////////////////
//  Container
//   -- centers page content

.container {
  max-width: 40rem;
  padding-left:  0rem;
  padding-right: 0rem;
  margin: 0 auto;
//  margin-left:  auto;
//  margin-right: auto;
}

///////////////////////////////////////////////////
//  Masthead

.masthead {
  padding-top:    1rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;

 .masthead-title {
    margin-top: 0;
    margin-bottom: 0;
    color: $masthead-color;
  
    a {
      color: $masthead-color;
    }
    
    small {
      color: $masthead-small-color;
      font-size: 75%;
      font-weight: 400;
      letter-spacing: 0;
    }
  }
}


//////////////////////////
// Posts

.post {
  margin-bottom: 3em;

  // note: only change margin for title,date if inside post block
  .post-title {
    margin-top: 0;
  }

  // Meta data line below post title
  .post-date {
    display: block;
    margin-top:  -.5rem;
    margin-bottom: 1rem;
  }
}

.post-title,
.post-title a {
  color: $post-title-color;
}

.post-date {
  color: $post-date-color;  
}
